import classes from './Success.module.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';

function Success(props){
    const navigate = useNavigate();

    const setOk=()=>{
        navigate('/');
    }
    return (
        <Container fluid className={classes.wrapper}>
          <Row>
            <Col className="col-sm-7 mx-auto text-center">
            <div className={classes.card}>
        <div className={classes.subcard}>
          <i class={classes.checkmark}>✓</i>
        </div>
          <h1>Zahtjev poslan</h1> 
          <p>Uspješno ste poslali zahtjev za transport!</p>
          <Button
              variant="primary"
              type="button"
              className={classes.sendButton}
              onClick={setOk}
            >
              <div className={classes.altSendButton}>
                <span className={classes.sendText}>OK</span>
              </div>
            </Button>
        </div>
            </Col>
        </Row>
        </Container>
    );
}

export default Success;