import Advantages from '../components/Advantages';
import Numbers from '../components/Numbers';
import Truck from '../components/Truck';
import Info from '../components/Info';
import Boxes from '../components/Boxes';
import MainText from '../components/MainText';

function MainPage(){
    return <div>
        <Truck />
        <MainText />
        <Advantages />
        <Numbers />
        <Boxes />
        <Info />
    </div>;
}

export default MainPage;