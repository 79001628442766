import Accordion from 'react-bootstrap/Accordion';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import classes from './Content.module.css';

import { useTranslation } from 'react-i18next'

function Content(){
  const { t } = useTranslation();
    return (
      <Container fluid className={classes.container}>
        <Row>
          <Col >
            <div className={classes.box}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("SERVICES.TRASNPORTATION.TITLE")}</Accordion.Header>
                <Accordion.Body>
                {t("SERVICES.TRASNPORTATION.DESCRIPTION")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>{t("SERVICES.LOGISTIC.TITLE")}</Accordion.Header>
                <Accordion.Body>
                {t("SERVICES.LOGISTIC.DESCRIPTION")}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>{t("SERVICES.CUSTOMS.TITLE")}</Accordion.Header>
                <Accordion.Body>
                {t("SERVICES.CUSTOMS.DESCRIPTION")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    );
}

export default Content;