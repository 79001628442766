import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


import { ReactComponent as Location } from "./location-icon.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Mail } from "./mail.svg";

import classes from './Info.module.css';

function Info() {

    const locationTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Naša lokacija!
        </Tooltip>
      );

      const phoneTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Naš broj!
        </Tooltip>
      );

      const mailTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Naša mail adresa!
        </Tooltip>
      );
    
  return (
    <Container fluid className={classes.container}>
      
      <Row>
        <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
        <OverlayTrigger
         placement="top"
         delay={{ show: 250, hide: 400 }}
         overlay={locationTooltip}
        >
          <div className={classes.infoBox}>
            <div>
              <Location />
            </div>
            <div className={classes.textBox}>Halilovići 16, Sarajevo</div>
          </div>
          </OverlayTrigger>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
        <OverlayTrigger
         placement="top"
         delay={{ show: 250, hide: 400 }}
         overlay={phoneTooltip}
        >
          <div className={classes.infoBox}>
            <div>
              <Phone />
            </div>
            <div className={classes.textBox}>(+387) 61 958 368</div>
          </div>
          </OverlayTrigger>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
        <OverlayTrigger
         placement="top"
         delay={{ show: 250, hide: 400 }}
         overlay={mailTooltip}
        >
        <div className={classes.infoBox}>
            <div>
              <Mail />
            </div>
            <div className={classes.textBox}>info@abbatlogistics.com</div>
          </div>
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  );
}

export default Info;
