import classes from './Boxes.module.css';

function Boxes(props){
    return <div className={classes.box}>
        <div className='d-flex w-100 justify-content-center'>
           <div className={classes.boxText}>{props.text}</div>
        </div>
    </div>;
}

export default Boxes;