
import Boxes from "../Boxes";
import AboutUsUpText from "./AboutUsUpText";
import AboutUsDownText from "./AboutUsDownText";

import { useTranslation } from 'react-i18next'

function AboutUsText(){
  const { t } = useTranslation();
return <div>
    <AboutUsUpText />
    <Boxes text={t("ABOUT_US.BOX")}/>
    <AboutUsDownText />
  </div>;
}


export default AboutUsText;