
import NavHeader from './components/NavHeader';
import {Route, Routes} from 'react-router-dom';

import MainPage from './pages/MainPage';
import ServicesPage from './pages/Services';
import AboutUSPage from './pages/AboutUs';
import ContactPage from './pages/ContactPage';
import Footer from './components/Footer';
import Transport from './pages/Transport';
import Success from './components/hiring/Success';
import Hiring from './pages/Hiring';


function App() {
  localStorage.setItem("lang", "GB");
  return (
    <div>
      <NavHeader />
     <Routes>
      <Route exact path='/' element={<MainPage />} />
      <Route path='/usluge' element={<ServicesPage />} />
      <Route path='/o-nama' element={<AboutUSPage />} />
      <Route path='/kontakt' element={<ContactPage />} />
      <Route path='/zahtjev-za-transport' element={<Transport />} />
      <Route path='/success' element={<Success />} />
      <Route path='/zaposljavamo' element={<Hiring />} />
     </Routes>
     <Footer />
    </div>
  );
}

export default App;
