import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import classes from './Advantages.module.css';

import { useTranslation } from 'react-i18next'

function Advantages() {
  const { t } = useTranslation();

  return (
    <Container fluid className={classes.container}>
      <div className={classes.advantagesTitle}>
        <h1>{t("HOME.ADVANTAGES.TITLE")}</h1>
      </div>
      <Row>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
          <div className={classes.contentBox}>
            <div className="w-100 d-flex justify-content-center">
              <h4>{t("HOME.ADVANTAGES.SECURITY.TITLE")}</h4>
            </div>

            <div className={classes.contentBoxText}>
            {t("HOME.ADVANTAGES.SECURITY.DESCRIPTION")}
            </div>
            <div className={classes.contentLine}></div>
          </div>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
          <div className={classes.contentBox}>
            <div className="w-100 d-flex justify-content-center">
              <h4>{t("HOME.ADVANTAGES.OPTIMIZATION.TITLE")}</h4>
            </div>

            <div className={classes.contentBoxText}>
            {t("HOME.ADVANTAGES.OPTIMIZATION.DESCRIPTION")}
            </div>
            <div className={classes.contentLine}></div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Advantages;
