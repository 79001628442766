import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { ReactComponent as PaperPlane } from "../paper-plane.svg";
import Button from 'react-bootstrap/Button';

import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import classes from './TransportForm.module.css';

function TransportForm(){
    const navigate = useNavigate();

    const { t } = useTranslation();

    const form = useRef();
   
    const sendEmail = (e) => {
      e.preventDefault(); // prevents the page from reloading when you hit “Send”
  
      emailjs.sendForm('service_7g0jv6s', 'template_hr487ia', form.current,'8-YAZguVPGnEI1PEd')
        .then((result) => {
            // show the user a success message
            navigate('/success');
            console.log('mail poslan');
        }, (error) => {
            // show the user an error
            console.log('mail nije poslan');
  
        });
    };

return (
    <Container fluid className={classes.container}>
        <Row className={classes.hiringText}>
            <Col className="col-sm-7 mx-auto text-center">
                <h3>{t("TRANSPORTATION_REQUEST.TITLE")}</h3>
                <div>
                {t("TRANSPORTATION_REQUEST.DESCRIPTION")}
                </div>
            </Col>
        </Row>
        <Row>
              <div className={classes.section}>
              <h5>{t("TRANSPORTATION_REQUEST.CLIENT_FORM.TITLE")}</h5>
              </div>
            </Row>
        <Row>
            <Col className="col-sm-8 mx-auto">
            <Form
            id="contact-form"
            ref={form}
            onSubmit={sendEmail}
          >
                <Row>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.CLIENT_FORM.FIELDS.FIRST_NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                   required
                    name="first_name"
                  
                  />
                </Form.Group>
              </Col>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.CLIENT_FORM.FIELDS.LAST_NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("TRANSPORTATION_REQUEST.CLIENT_FORM.FIELDS.COMPANY_NAME")}
              </Form.Label>
              <Form.Control type="text" name="company" />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("TRANSPORTATION_REQUEST.CLIENT_FORM.FIELDS.EMAIL")}
              </Form.Label>
              <Form.Control type="email" name="email" />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("TRANSPORTATION_REQUEST.CLIENT_FORM.FIELDS.PHONE")}
              </Form.Label>
              <Form.Control type="text" name="phone" />
            </Form.Group>
            <Row>
              <div className={classes.section}>
              <h5>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.TITLE")}</h5>
              </div>
            </Row>
            <Row>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.LOADING_DATE")}</Form.Label>
                  <Form.Control
                    type="date"
                   required
                    name="date_from"
                  
                  />
                </Form.Group>
              </Col>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.UNLOADING_DATE")}</Form.Label>
                  <Form.Control
                    type="date"
                    name="date_to"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {" "}
                <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.LOADING_LOCATION")}</Form.Label>
              <Form.Control
                type="text"
                name="loading_location"
                required
              />
            </Form.Group>

              </Col>
              <Col>
                {" "}
                <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.UNLOADING_LOCATION")}</Form.Label>
              <Form.Control
                type="text"
                name="unloading_location"
                required
              />
            </Form.Group>

              </Col>
            </Row>

            <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.NUMBER_OF_LOCATIONS")}</Form.Label>
              <Form.Control
                type="text"
                name="delivery_phone"
                required
              />
            </Form.Group>

            <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.TYPE")}</Form.Label>
              <Form.Control
                type="text"
                name="type"
                required
              />
            </Form.Group>

            <Form.Group className="mb-20 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.ESTIMATED_VALUE")}</Form.Label>
              <Form.Control
                type="text"
                name="estimated_value"
                required
              />
            </Form.Group>

            <Row>
              <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
                {" "}
                <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.NUMBER_OF_EURO_PALLETS")}</Form.Label>
              <Form.Control
                type="text"
                name="quantity"
                required
              />
            </Form.Group>

              </Col>
              <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
                {" "}
                <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.MASS")}</Form.Label>
              <Form.Control
                type="text"
                name="mass"
                required
              />
            </Form.Group>

              </Col>
              <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
                {" "}
                <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.DIMENSION")}</Form.Label>
              <Form.Control
                type="text"
                name="dimension"
                required
              />
            </Form.Group>

              </Col>
            </Row>

            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.DEADLINE")}?
              </Form.Label>
              <Form.Control type="text" name="deadline" />
            </Form.Group>

            <Form.Group
              className="mb-3 form-group w-100"
              
            >
              <Form.Label className={classes.labelText}>
              {t("TRANSPORTATION_REQUEST.GENERAL_FORM.FIELDS.NOTE")}</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows={3}
              />
            </Form.Group>

            <Form.Group
              className="mb-3 form-group w-100"
              
            >
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className={classes.sendButton}
            >
              <div className={classes.altSendButton}>
                <PaperPlane className={classes.paper} />
                <span className={classes.sendText}>{t("TRANSPORTATION_REQUEST.BTN")}</span>
              </div>
            </Button>
          </Form>
            </Col>
        </Row>
    </Container>
);
}

export default TransportForm;