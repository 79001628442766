import React, {useState} from "react";
import i18n from '../../i18n';

import classes from './LanguageSelector.module.css';
import ReactFlagsSelect from "react-flags-select";


const LanguageSelector = () => {

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        e.preventDefault();
        // console.log("LANGUAGE", e.target.value);
        i18n.changeLanguage(e.target.value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e.target.value);
    }

    function changeLanguage(code) {
        i18n.changeLanguage(code);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(code);
        localStorage.setItem("lang", code);
    }

    return (
        // <select defaultValue={selectedLanguage} onChange={chooseLanguage}>  
        //     <option className={classes.test} value="de">German</option>
        //     <option value="en">English</option>
        //     <option value="ba">Bosnian</option>
        // </select>

        <ReactFlagsSelect
        countries={[ "GB", "DE", "BA"]}
        customLabels={{ GB: "EN",DE: "DE", BA: "BA" }}
        selected={selectedLanguage}
        onSelect={(code) => changeLanguage(code)}
        onChange={chooseLanguage}    
        className={classes.language}    
      />
    );
};

export default LanguageSelector;