import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

import classes from './Numbers.module.css';
import { useState } from "react";

import { useTranslation } from 'react-i18next'


function Numbers(){

    const [counterOn, setCounterOn]= useState(false);

    const { t } = useTranslation();


    return (
        <Container fluid> 
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
        <Row className={classes.coverBox}>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
            <div className={classes.numberBox}>
                <h4>
                    { counterOn &&  <CountUp start={0} end={50}  duration={2} delay={0}/> }
                   +
                    </h4>
                <div>{t("HOME.NUMBERS.CITIES.TITLE")}</div>
            </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
            <div className={classes.numberBox}>
                <h4>1000+</h4>
                <div>{t("HOME.NUMBERS.KILOMETERS.TITLE")}</div>
            </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
            <div className={classes.numberBox}>
                <h4>
                { counterOn &&  <CountUp start={0} end={30}  duration={2} delay={0}/> }
                +
                </h4>
                <div>{t("HOME.NUMBERS.CLIENTS.TITLE")}</div>
            </div>
            </Col>
        </Row>
        </ScrollTrigger>
        </Container>
    );
}

export default Numbers;