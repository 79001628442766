import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import classes from './ServiceContent.module.css';

import movment from './selidbe.png';

import Content from '../about-us/Content';

function ServiceContent(){
    return (
      <Container fluid className={classes.content}>
        <Row>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
     
          <Content />
         
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
        <div className={classes.pictureBox}>
              <img alt="" src={movment} className={classes.picture}></img>
            </div>
        </Col>
        </Row>
  
      </Container>
    );
}

export default ServiceContent;