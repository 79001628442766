import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import classes from './AboutUsUpText.module.css';

import { useTranslation } from 'react-i18next'

function AboutUsUpText(){
    const { t } = useTranslation();
    return (
        <Container fluid className={classes.container}>
            <Row>
                <Col>
        <div className={classes.containerText}>
        <span className={classes.abbat}>{t("ABOUT_US.NAME")}</span> 
        {t("ABOUT_US.DESCRIPTION")}
         </div>
                </Col>
            </Row>
            </Container>
    );
}

export default AboutUsUpText;