
import TransportForm from "../components/hiring/TransportForm";

function Transport(){

    return <div>
        <TransportForm />
    </div>;
}

export default Transport;