import ContactForm from "../components/contacts/ContactForm";
import ContactMap from "../components/contacts/ContactMap";

function ContactPage(){
    return <div>
        <ContactForm />
        <ContactMap />
    </div>;
}

export default ContactPage;