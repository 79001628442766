
import AboutUsText from "../components/about-us/AboutUsText";


function AboutUSPage(){
    return <div>
  
        <AboutUsText />
        {/* <Boxes text='Nudimo razne vrste usluga...' />
        <Content /> */}
    </div>;
}

export default AboutUSPage;