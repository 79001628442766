import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function Toaster(props){
    return <div>
            <ToastContainer className="p-3" position='top-center'>
          <Toast show={props.showA} onClose={props.toggleShowA}>
            <Toast.Header closeButton={true}>
              <strong className="me-auto">ABBAT</strong>
            </Toast.Header>
            <Toast.Body>Vaš mail je poslan.</Toast.Body>
          </Toast>
        </ToastContainer>
    </div>;
}

export default Toaster;