import GoogleMapReact from 'google-map-react';
import classes from './ContactMap.module.css';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Marker = props => {

  return (

    <div>

    <div className={classes.marker}></div>

  <span className={classes.beacon}></span>

  </div>

  );

}

function ContactMap(){

    const defaultProps = {
        center: {
          lat: 43.84876692925485,
          lng: 18.344713194627776
        },
        zoom: 15
      };

    return <div style={{ height: '100vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyCrVtiUZrkZY5AILvSzrFlArB2AMQKY2t4" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}

    >
      <Marker  
        lat={43.84876692925485}
        lng={18.344713194627776}
         />
    </GoogleMapReact>
  </div>
}

export default ContactMap;