import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from '../locales/en/translation.json';
import translationsInGerman from '../locales/de/translation.json';
import transaltionInBosnian from '../locales/ba/translation.json';
// the translations
const resources = {
  GB: {
    translation: translationsInEng
  },
  DE: {
    translation: translationsInGerman
  },
  BA : {
    translation : transaltionInBosnian
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources, // resources are important to load translations for the languages.
    lng: 'GB', // It acts as default language. When the site loads, content is shown in this language.  
    debug: true,
    fallbackLng: "DE", // use de if selected language is not available
    interpolation: {
      escapeValue: false
    },
    ns: "translation", // namespaces help to divide huge translations into multiple small files.
    defaultNS: "translation"
  });

export default i18n;