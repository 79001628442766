import classes from './Truck.module.css';
function Truck(){
    return (
      <div className={classes.loopwrapper}>
        <div className="w-100 d-flex justify-content-center mt-20">
        </div>
        <div className={classes.mountain}></div>
        <div className={classes.hill}></div>
        <div className={classes.tree}></div>
        <div className={classes.tree}></div>
        <div className={classes.tree}></div>
        <div className={classes.rock}></div>
        <div className={classes.truck}></div>
        <div className={classes.wheels}></div>
      </div>
    ); 
}

export default Truck;