import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import { useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';

import Cards from './Cards';
import classes from './HiringForm.module.css';
import { ReactComponent as PaperPlane } from "../paper-plane.svg";
import Toaster from '../contacts/Toaster';

import { useTranslation } from 'react-i18next'

function HiringForm(){

  const { t } = useTranslation();


    const cancelCourse = () => { 
        document.getElementById("contact-form").reset();
      }
      const [field, setField] = useState([""]);

    const [showA, setShowA] = useState(false);

    const toggleShowA = () => setShowA(!showA);

    const form = useRef();

   
    const sendEmail = (e) => {
      e.preventDefault(); // prevents the page from reloading when you hit “Send”

      console.log(form.current);
      console.log(field);

      let str = "";
      field.map((item) => {
        str += `${item} \n`;
      });

      var request = {
        first_name : document.getElementsByName('first_name')[0].value,
        last_name : document.getElementsByName('last_name')[0].value,
        birth_date : document.getElementsByName('birth_date')[0].value,
        category : str,
        email : document.getElementsByName('email')[0].value,
        phone : document.getElementsByName('phone')[0].value,
        message : document.getElementsByName('message')[0].value,

      };

      console.log(request);

      emailjs.send('service_7wt1sld', 'template_j3akc12',request,'H9uCEdt_Zl2VfG-Xx' ).then(
        function (response) {
            // console.log("SUCCESS!", response.status, response.text);

            toggleShowA();
            cancelCourse();
            
          },
          function (error) {
            // console.log("FAILED...", error);
          }
      );
  
    //   emailjs.sendForm('service_7wt1sld', 'template_j3akc12', form.current,'H9uCEdt_Zl2VfG-Xx')
    //     .then((result) => {
    //         // show the user a success message
    //         toggleShowA();
    //         cancelCourse();
    //     }, (error) => {
    //         // show the user an error
  
    //     });
    };

    return (
        <div>
        <Container fluid className={classes.container} >
        <Toaster showA={showA}  toggleShowA={toggleShowA}/>
            <Cards />
            <Row>
            <Col className="col-sm-6 mx-auto">
            <Form
            id="contact-form"
            ref={form}
            onSubmit={sendEmail}
          >
                <Row>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("CAREER.FORM.FIRST_NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                   required
                    name="first_name"
                  
                  />
                </Form.Group>
              </Col>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("CAREER.FORM.LAST_NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("CAREER.FORM.BIRTH_DATE")}
              </Form.Label>
              <Form.Control type="date" name="birth_date" />
            </Form.Group>
            <Form.Group as={Col} controlId="my_multiselect_field">
      <Form.Label>{t("CAREER.FORM.CATEGORIES")}</Form.Label>
      <Form.Control type="category" as="select" multiple value={field} onChange={e => setField([].slice.call(e.target.selectedOptions).map(item => item.value))}>
      <option value=""></option>
        <option value="B">B</option>
        <option value="C1">C1</option>
        <option value="C">C</option>
        <option value="D1">D1</option>
        <option value="D">D</option>
        <option value="BE">BE</option>
        <option value="C1E">C1E</option>
        <option value="CE">CE</option>
        <option value="D1E">D1E</option>
        <option value="DE">DE</option>
      </Form.Control>
    </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("CAREER.FORM.EMAIL")}
              </Form.Label>
              <Form.Control type="email" name="email" />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("CAREER.FORM.PHONE")}
              </Form.Label>
              <Form.Control type="text" name="phone" />
            </Form.Group>

            <Form.Group
              className="mb-3 form-group w-100"
              
            >
              <Form.Label className={classes.labelText}>{t("CAREER.FORM.DESCRIPTION")}</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows={3}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className={classes.sendButton}
            >
              <div className={classes.altSendButton}>
                <PaperPlane className={classes.paper} />
                <span className={classes.sendText}>SEND</span>
              </div>
            </Button>
            </Form>
            </Col>
            </Row>
        </Container>
        </div>
    );
}

export default HiringForm;