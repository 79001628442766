import classes from './Cards.module.css';

import { useTranslation } from 'react-i18next'

function Cards(){

  const { t } = useTranslation();

    return (
        <div className={classes.container}>
  <div className={classes.card}>
    <div className={classes.box}>
      <div className={classes.content}>
        <h2>01</h2>
        <h3>{t("CAREER.STEP_ONE.TITLE")}</h3>
        <p>{t("CAREER.STEP_ONE.DESCRIPTION")}</p>
   
      </div>
    </div>
  </div>

  <div className={classes.card}>
    <div className={classes.box}>
      <div className={classes.content}>
        <h2>02</h2>
        <h3>{t("CAREER.STEP_TWO.TITLE")}</h3>
        <p>{t("CAREER.STEP_TWO.DESCRIPTION")}</p>

      </div>
    </div>
  </div>

  <div className={classes.card}>
    <div className={classes.box}>
      <div className={classes.content}>
        <h2>03</h2>
        <h3>{t("CAREER.STEP_THREE.TITLE")}</h3>
        <p>{t("CAREER.STEP_THREE.DESCRIPTION")}</p>

      </div>
    </div>
  </div>
</div>
    );
}

export default Cards;