import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import classes from './MainText.module.css'

import { useTranslation } from 'react-i18next'

function MainText(){
    const { t } = useTranslation();

    return(
        <Container fluid className={classes.container}>
            <Row>
                <Col>
                <h3>{t("HOME.MAIN_TEXT.TITLE")}</h3>
                <div className={classes.containerText}>
                {t("HOME.MAIN_TEXT.DESCRIPTION")}
                </div>
                </Col>
            </Row>
        </Container>
    );
}

export default MainText;    