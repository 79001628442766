
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as LinkedIn } from "./olxro.svg";
import ab from './abred.svg';




import classes from './Footer.module.css';
import { Col, Container, Row } from 'react-bootstrap';

function Footer(){
    return (
      <Container fluid  className={classes.footer}>
         <Row>
      
      
      
        <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
        <div class="container" className={classes.logoWrapper}>
      <img alt='' src={ab} className={classes.logo}></img>
        </div>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
        <div className={classes.social}>
          <div className={classes.socialLink}>
          <a href='https://www.instagram.com/'> <Instagram /></a>
          <a href='https://www.facebook.com/'> <Facebook /></a>
          <a href='https://olx.ba/'> <LinkedIn /></a>
          </div>
        </div>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={12} sm={12}>
        <div className={classes.copyright} >
          <small>Copyright &copy; ABBAT doo</small>
        </div>
        </Col>
       
      

     
      </Row>
      </Container>
    );
}

export default Footer;