import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import emailjs from '@emailjs/browser';
import { useRef , useState } from 'react';

import classes from './ContactForm.module.css';

import { ReactComponent as Location } from "../location-icon.svg";
import { ReactComponent as Phone } from "../phone.svg";
import { ReactComponent as Mail } from "../mail.svg";
import { ReactComponent as PaperPlane } from "../paper-plane.svg";
import Toaster from './Toaster';

import { useTranslation } from 'react-i18next'

function ContactForm(){

  const { t } = useTranslation();


  const cancelCourse = () => { 
    document.getElementById("contact-form").reset();
  }

  const [showA, setShowA] = useState(false);

  const toggleShowA = () => setShowA(!showA);


  const form = useRef();
 
  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs.sendForm('service_ch0falc', 'template_wtyq8hd', form.current, '8-YAZguVPGnEI1PEd')
      .then((result) => {
          // show the user a success message
          toggleShowA();
          cancelCourse();
      }, (error) => {
          // show the user an error

      });
  };

    return (
      <div className={classes.contactPage}>
       <Toaster showA={showA}  toggleShowA={toggleShowA}/>
        <h1 className={classes.sectionHeader}>{t("CONTACT.TITLE")}</h1>
        <div className={classes.contactWrapper}>
          <Form
            id="contact-form"
            ref={form}
            onSubmit={sendEmail}
            className={classes.formHorizontal}
          >
            <Row>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("CONTACT.FIRST_NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                   required
                    name="first_name"
                  
                  />
                </Form.Group>
              </Col>
              <Col>
                {" "}
                <Form.Group className="form-group">
                  <Form.Label className={classes.labelText}>{t("CONTACT.LAST_NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="form-group">
              <Form.Label className={classes.labelText}>
              {t("CONTACT.PHONE")}
              </Form.Label>
              <Form.Control type="text" name="phone_number" />
            </Form.Group>

            <Form.Group className="mb-10 form-group">
              <Form.Label className={classes.labelText}>{t("CONTACT.EMAIL")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
              />
            </Form.Group>

            <Form.Group
              className="mb-3 form-group w-100"
              
            >
              <Form.Label className={classes.labelText}>{t("CONTACT.MESSAGE")}</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows={3}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className={classes.sendButton}
            >
              <div className={classes.altSendButton}>
                <PaperPlane className={classes.paper} />
                <span className={classes.sendText}>SEND</span>
              </div>
            </Button>
          </Form>

          <div className={classes.directContactContainer}>
            <ul className={classes.contactList}>
              <li className={classes.listItem}>
                <Location className={classes.icon} />
                <span className={classes.contactText}>Halilovići 16, Sarajevo</span>
              </li>

              <li className={classes.listItem}>
                <Phone className={classes.icon} />
                <span className={classes.contactText}>(+387) 61 958 368</span>
              </li>

              <li className={classes.listItem}>
                <Mail className={classes.icon} />
                <span className={classes.contactText}>info@abbatlogistics.com</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
    
}

export default ContactForm;