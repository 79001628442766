import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import classes from './AboutUsDownText.module.css';

import { useTranslation } from 'react-i18next'

function AboutUsDownText(){
  const { t } = useTranslation();
    return (
        <Container fluid className={classes.container}>
            <Row>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
          <div className={classes.contentBox}>
            <div className="w-100 d-flex justify-content-center">
              <h4>{t("ABOUT_US.VISION.TITLE")}</h4>
            </div>

            <div className={classes.contentBoxText}>
            {t("ABOUT_US.VISION.DESCRIPTION")}
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
          <div className={classes.contentBox}>
            <div className="w-100 d-flex justify-content-center">
              <h4>{t("ABOUT_US.MISION.TITLE")}</h4>
            </div>

            <div className={classes.contentBoxText}>
            {t("ABOUT_US.MISION.DESCRIPTION")}
            </div>
          </div>
        </Col>
            </Row>
            </Container> 
    );
}

export default AboutUsDownText;