import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import LanguageSelector from '../components/language/LanguageSelector';

// import ab from './ab2.png';
import ab from './abred.svg';
import classes from './NavHeader.module.css';

import { useTranslation } from 'react-i18next'

function NavHeader(){
const { t } = useTranslation();

const customStyles = {
  padding: '0', // Remove padding
  /* Add any other custom styles here */
  border : '0',
  boxShadow : 'none'
  
};


    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container >
        <div className={classes.testContent}>
        <Navbar.Brand as={Link} to="/" >
          <Row>
            <Col className={classes.coverpicture}>
            <img alt="" src={ab} className={classes.picture}></img>
            </Col>
            <Col className='p-0'>
            <div className={classes.navb}>
           <div className={classes.brandheadname}>
         ABBAT doo
         </div>
       <div className={classes.brandsubname}>Transport & Logistics</div>
           </div>
            </Col>
          </Row>
      
          
          </Navbar.Brand>
       
            <div className={classes.selectlanguage_mobile}>
            <LanguageSelector />
            </div>
     
          
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={customStyles} />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
      
        
       <Nav className={classes.navContent}>
         <Nav.Item className={classes.mobile} >
           <Nav.Link as={NavLink} to="/"  style={({ isActive }) => ({ 
                            color: isActive ? '#BB000E' : 'white' })}>
             
             {t("HEADER.MAIN_PAGE")}
           </Nav.Link>
         </Nav.Item>
         <Nav.Item className={classes.mobile}>
           <Nav.Link as={NavLink} to="/o-nama" style={({ isActive }) => ({ 
                            color: isActive ? '#BB000E' : 'white' })}>
             {t("HEADER.ABOUT_US")}
           </Nav.Link>
         </Nav.Item>
         <Nav.Item className={classes.mobile}>
           <Nav.Link as={NavLink} to="/usluge" style={({ isActive }) => ({ 
                            color: isActive ? '#BB000E' : 'white' })}>
             {t("HEADER.SERVICES")}
           </Nav.Link>
         </Nav.Item>
         <Nav.Item className={classes.mobile}>
           <Nav.Link as={NavLink} to="/zahtjev-za-transport" style={({ isActive }) => ({ 
                            color: isActive ? '#BB000E' : 'white' })}>
             {t("HEADER.TRANSPORT_REQUEST")}
           </Nav.Link>
         </Nav.Item>
         <Nav.Item className={classes.mobile}>
           <Nav.Link as={NavLink} to="/zaposljavamo" style={({ isActive }) => ({ 
                            color: isActive ? '#BB000E' : 'white' })}>
              {t("HEADER.HIRING")}
           </Nav.Link>
         </Nav.Item>
         <Nav.Item className={classes.mobile}>
           <Nav.Link as={NavLink} to="/kontakt" style={({ isActive }) => ({ 
                            color: isActive ? '#BB000E' : 'white' })}>
              {t("HEADER.CONTACT")}
           </Nav.Link>
         </Nav.Item>

       </Nav>
       
        </Navbar.Collapse>
        
      </Container>
      <div  className={classes.selectlanguage_desktop}>
      <LanguageSelector />
      </div>
    </Navbar>
    );
}

export default NavHeader;